import React from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

import {
  List,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'

import {
  ChevronLeft as ChevronLeftIcon,
  Videocam as VideoCamIcon,
  Schedule as ScheduleIcon,
  GridOn as GridOnIcon,
  Visibility as VisibilityIcon,
  LiveTv as LiveTvIcon,
  Error as ErrorIcon,
} from '@material-ui/icons/'
import styles from './styles'

const Nav = ({ open, handleDrawerClose }) => {
  const classes = styles()

  const navItemsArray = [
    { text: 'Dashboard', icon: <GridOnIcon />, path: '/' },
    { text: 'Schedule', icon: <ScheduleIcon />, path: '/events' },
    { text: 'Issues', icon: <ErrorIcon />, path: '/issues' },
  ]

  const NavItems = (
    <div>
      {navItemsArray.map(({ text, icon, path }, index) => (
        <NavLink key={index} to={path} className={classes.unsetAnchor}>
          <ListItem button button key={text}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        </NavLink>
      ))}
    </div>
  )

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
      }}
      open={open}>
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>{NavItems}</List>
      <Divider />
    </Drawer>
  )
}

export default Nav
