import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import RatingSelector from '../RatingSelector'
import Rating from '@material-ui/lab/Rating'
import Box from '@material-ui/core/Box'
import socket from '../../ws/index'


const defaults = _ => {
  return {
  score: { title: 'Score', tooltip: null, score: null }//,
  //score_image: { title: 'Image', tooltip: null, score: null },
  //score_stream: { title: 'Stream', tooltip: null, score: null},
  // score_sound: { title: 'Sound', tooltip: null, score: null },
  // score_installation: { title: 'Installation', tooltip: null, score: null},
}
}


const RatingBox = (props) => {

  const [scores, setScores] = useState({...defaults()})

  const reduce_score = (new_score) => {
    if (!new_score) {
      setScores({...defaults()}) 
      return
    }
    const new_scores = { ...scores }
    new_scores['score'].score = new_score.score
    // new_scores['score_image'].score = new_score.score_image
    // new_scores['score_sound'].score = new_score.score_sound
    // new_scores['score_stream'].score = new_score.score_stream
    // new_scores['score_installation'].score = new_score.score_installation
    setScores({...new_scores})
  }


  useEffect(() => {

    socket.emit('REQUEST_SCORE', { event_guid: props.event_guid, user_id: props.user_id, scores: scores})
  
    socket.on('SCORE_RETREIVED', score => 
      reduce_score(score)
    )
    setScores({...defaults()})
    
    // socket.on('SCORE_UPDATED', score => {
    //   reduce_score(score)
    // })
  }, [props.event_guid])

  const report_scores = (scores) => {
    socket.emit('REPORT_SCORE', { event_guid: props.event_guid, user_id: props.user_id, scores: scores})
  }

  const update_score = (sel, val) => {
    const new_scores = { ...scores }
    new_scores[sel].score = val
    setScores({...new_scores})
    report_scores({...new_scores})
  }

  return (
    <div key={`score-kk-score-${props.event_guid}`}>
      {Object.keys(scores).map(key => 
          <div>
          <RatingSelector
            key={`score-kk-${key}-${props.event_guid}`}
            hash={`score-kk-${key}-${props.event_guid}`}
            selector={key}
            {...scores[key]}
            onScoreUpdated={update_score}
          />
          </div>
      )}
    </div>
  )
}

export default RatingBox
