import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import Box from '@material-ui/core/Box'

const labels = {
  0.5: 'Completely Broken',
  1: 'Useless',
  1.5: 'Very Poor',
  2: 'Poor',
  2.5: 'Acceptable',
  3: 'Ok',
  3.5: 'Good',
  4: 'Very Good',
  4.5: 'Excellent',
  5: 'Perfect'
}

const useStyles = makeStyles({
  root: {
    // width: 200,
    // display: 'flex',
    // alignItems: 'center',
  }
})

const RatingSelector = props => {
  const [value, setValue] = useState(props.score / 2)
  const [hover, setHover] = useState(-1)
  const classes = useStyles()

  return (
    <div
      key={`id-key-${props.event_guid}-${props.selector}`}
      className={classes.root}
      style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ minWidth: '30%' }}>
        <h4 style={{ margin: 0, fontWeight: 400, color: '#2b2b2b' }}>
          {props.title}
        </h4>
        <p>{props.tooltip}</p>
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Rating
            name={`${props.event_guid}-${props.selector}-rating`}
            value={props.score / 2}
            precision={0.5}
            key={`id-key-rating-${props.selector}`}
            onChange={(event, newValue) => {
              setValue(newValue)
              props.onScoreUpdated(props.selector, newValue * 2)
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover)
              setTimeout(_ => {
                setHover(-1)
              }, 2000)
            }}
          />
          {value !== null && hover !== -1 && (
            <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
          )}
        </div>
      </div>
    </div>
  )
}

export default RatingSelector
