import React, { useState, useEffect, forwardRef, useRef } from 'react'
import Chart from "react-apexcharts";
import moment from 'moment'

const MultipleYAxisChart = () => {

    const [labels, setLabels] = useState([])
    const [series, setSeries] = useState([])
    const [ymax, setymax] = useState(10)


    useEffect(_ => {
        fetch("/api/stats/data/graph/games")
        .then(response => response.json())
        .then(stats => {
            console.log(stats)
            const gam = stats.map(stat => {return parseInt(stat.event_count)})
            const sco = stats.map(stat => {return parseInt(stat.average_score)})
            const iss = stats.map(stat => {return parseInt(stat.issue_count)})
            const lab = stats.map(stat => {return moment.utc(stat.event_day).format('MM/DD/YYYY')})
            console.log("labale:", lab)
            const new_series =  [{
                name: 'Games Broadcasted',
                type: 'column',
                data: gam,
            }, {
                name: 'Games with Issues',
                type: 'column',
                data: iss
            }, {
                name: 'Average Quality',
                type: 'line',
                data: sco
            },
        ]
            setSeries( new_series)
            const new_opts = {...options}
            new_opts.labels = lab
            const newYMax = Math.max(...gam)*1.05
            new_opts.yaxis[0].max = newYMax
            new_opts.yaxis[1].max = newYMax
            /* new_opts.yaxis[2].max = newYMax */
            setOptions(new_opts)
            console.log("timeline", new_series)
        })
    }, []) 
    
    const [options, setOptions] = useState({
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
            },
            stroke: {
                width: [1, 1, 4],
                curve: 'smooth',
            },
            colors:['#008FFB', '#00E396', '#FEB019'],

            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },

            fill: {
                opacity: [0.85, 0.10, 1],
                gradient: {
                  inverseColors: false,
                  shade: 'light',
                  type: "vertical",
                  opacityFrom: 0.85,
                  opacityTo: 0.55,
                  stops: [0, 100, 100, 100]
                }
              },
            labels: labels,
            markers: {
                size: 0
            },
            xaxis: {
                type: 'datetime'
            },
            yaxis: [{
                title: {
                    text: 'Games',
                    style: {
                      color: '#008FFB',
                    }
                },
                min: 0,
                forceNiceScale: false,
                axisticks: true,
                axisBorder: {
                  show: true,
                  color: '#008FFB'
                },
                labels: {
                  style: {
                    colors: '#008FFB',
                  }
                },
            },{
                title: {
                    text: 'Issues',
                    style: {
                      color: '#00E396',
                    }
                },
                forceNiceScale: true,
                show:false,
                axisticks: true,
                axisBorder: {
                  show: true,
                  color: '#00E396'
                },
                labels: {
                  style: {
                    colors: '#00E396',
                  }
                },
                opposite: true, 
            },{
              title: {
                  text: 'Score',
                  style: {
                    color: '#FEB019',
                  }
              },
              min: 0,
              max: 10,
              forceNiceScale: false,
              axisticks: true,
              axisBorder: {
                show: true,
                color: '#FEB019'
              },
              labels: {
                style: {
                  colors: '#FEB019',
                }
              },
              opposite: true,
          }],
            legend: {
                show: false
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + "";
                        }
                        return y;

                    }
                }
            }
    })


    return (
        <Chart options={options} series={series} type="line" height={350} />
    )
}

export default MultipleYAxisChart