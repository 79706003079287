import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import {
  AppBar,
  Toolbar,
  Typography,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
  // ListItemSecondaryAction,
  ListItemIcon,
  Divider
} from '@material-ui/core'

import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  AccountCircle as AccountCircleIcon,
  Videocam as VideoCamIcon
} from '@material-ui/icons/'

import styles from './styles'
import UserContext from '../../Context/userContext'
import { handleSignOut } from '../../Auth'
import Search from '../Search'
import Backdrop from '../Backdrop'
import moment from 'moment'
import { access } from '../../Utils'

import Logo from '../../Assets/Logo'

const Header = ({ handleDrawerOpen, open }) => {
  const classes = styles()
  const [userAnchor, setUserAnchor] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)

  // Search 1.5 seconds after the user has finished typing
  let searchTimeout = null

  const handleMenuOpen = ({ currentTarget }) => setUserAnchor(currentTarget)
  const handleMenuClose = _ => setUserAnchor(null)

  const handleSearch = ({ currentTarget }) => {
    searchTimeout && clearTimeout(searchTimeout)

    const { value } = currentTarget

    // Clear search results if search term empty/whitespace
    if (value.trim().length === 0) {
      setSearchLoading(false)
      return setSearchResults([])
    }

    searchTimeout = setTimeout(_ => {
      setSearchLoading(true)
      fetch(`/api/search/${value}`)
        .then(r => r.json())
        .then(json => {
          setSearchResults(json)
          setSearchLoading(false)
        })
        .catch(console.error)
    }, 750)
  }

  const handleSearchClose = _ => {
    setSearchLoading(false)
    setSearchResults([])
  }

  const searchResultMapper = ({ content_uuid, event_guid, event, camera, league, thing_name, event_start }, index) => (
    
    <Link
      to={`/events/${content_uuid || event_guid}`}
      className={classes.anchorUnset}
      key={index}>
      <ListItem onClick={handleSearchClose}>
        <ListItemIcon>
          <VideoCamIcon />
        </ListItemIcon>
        <ListItemText
          primary={`${moment.utc(event_start).local().format('DD.MM.YYYY HH:mm')} - ${event}`}
          secondary={`${league} - ${thing_name}`}
        />
        {/* Show the broadcasting state in the secondaryAction */}
        {/* <ListItemSecondaryAction>
          <IconButton edge='end' aria-label='delete'></IconButton>
        </ListItemSecondaryAction> */}
      </ListItem>
      <Divider />
    </Link>
  )

  return (
    <AppBar
      position='absolute'
      className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.titleContainer}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}>
            <MenuIcon />
          </IconButton>
          <Link
            className={clsx([classes.titleContainer, classes.mobileHide])}
            to='/'>
            <Logo
              className={clsx(classes.logo, open && classes.logoMenuOpen)}
            />
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              className={classes.title}>
              Feedback
            </Typography>
          </Link>
        </div>
        <div>
          <Search onChange={handleSearch} isLoading={searchLoading} />
          {searchResults.length !== 0 && (
            <div style={{position: 'relative'}} >
              <Paper className={classes.searchResultsContainer}>
                <List>{searchResults.map(searchResultMapper)}</List>
              </Paper>
              <Backdrop onClick={handleSearchClose} />
            </div>
          )}
        </div>
        <UserContext.Consumer>
          {user => (
            <div>
              <IconButton color='inherit'>
                <Badge color='secondary'>
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton color='inherit' onClick={handleMenuOpen}>
                <AccountCircleIcon />
              </IconButton>
              <Menu
                anchorEl={userAnchor}
                open={Boolean(userAnchor)}
                onClose={handleMenuClose}>
                <MenuItem disabled>My Account</MenuItem>
                <MenuItem onClick={handleSignOut}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </UserContext.Consumer>
      </Toolbar>
    </AppBar>
  )
}

export default Header
