import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Chip } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'


import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  PauseCircleFilled as PauseCircleFilledIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from '@material-ui/icons'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import socket from '../../ws/index'
import moment from 'moment'
import Rating from '@material-ui/lab/Rating'


const columns = [
  {
    field: 'Status',
    title: 'status',
    lookup: { active: 'Streaming', scheduled: 'Scheduled', inactive: 'Inactive' },
    render: rowData => (
      <Chip
        color={rowData.status === 'active' ? 'primary' : 'default'}
        size='small'
        label={rowData.status === 'active' ? 'Streaming' : 'Scheduled'}
        icon={
          rowData.status === 'active' ? (
            <PlayCircleFilledIcon />
          ) : (
            <PauseCircleFilledIcon />
          )
        }
      />
    )
  },
  { title: 'Name', field: 'event',   render: rowData => (
    <Link
      to={`/events/${rowData.content_uuid || rowData.event_guid}`}>
      {rowData.event}
    </Link> )},
  { 
    title: 'league', 
    field: 'league' },
  {
    title: 'Start',
    field: 'event_start',
    render: ({ event_start }) => moment.utc(event_start).local().format('DD.MM.YYYY HH:mm')
  },
  {
    title: 'Average Score',
    field: 'avg_score',
    render: rowData => {
      return rowData.avg_score ? (
      <Rating name="disabled" value={rowData.avg_score / 2} disabled />
    ) : <span/>}
  },
  {
    title: 'Issues Recorded',
    field: 'issue_count',
  },
  {
    title: 'Issues',
    field: 'issue_list',
    render: rowData => {
      return rowData.issue_list.join(", ")
    }
  },
  { title: 'visible', field: 'visible' , render: rowData => rowData.permission == 'all' ? 'website' : 'internal',
    lookup: { internal: 'internal', website: 'website' , all: 'all'},
},
  { title: 'content id', field: 'content_uuid' ,   render: rowData => (
    <a 
      href={`https://www.sporttotal.tv/${rowData.content_uuid}`}>
      {rowData.content_uuid}
    </a>)},
]

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const Home = _ => {

  const tableRef = useRef()
  const [videos, setVideos] = useState([])

  useEffect(() => {
    socket.on("VIDEOS_LISTED", vid => {
      setVideos(vid)
    })
    socket.emit("REQUEST_VIDEOS_WITH_ISSUES")
  }, [])

  return (
    <div>

    <MaterialTable
      tableRef={tableRef}
      icons={tableIcons}
      columns={columns}
      title="Games with Issues"
      data={videos}
      options={{
        sorting: true,
        filtering: true,
        pageSize: videos.length > 100 ? 100 : 50,
        exportButton: true,
        exportAllData: true
      }}
    />
    </div>
  )
}

export default Home
