import React, { StrictMode, useState, useEffect, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import UserContext from './Context/userContext'

import { handleAuth, fetchUser, refreshTokens, isUserAuthorized } from './Auth'
import Unauthorized from './Auth/Unauthorized'

import Layout from './Components/Layout'
import Spinner from './Components/Spinner'
import Events from './Pages/Events'
import Issues from './Pages/Issues'
import EventDetail from './Pages/EventDetail'
import Dashboard from './Pages/Dashboard'
import socket from './ws/index'

const App = _ => {
  const [user, setUser] = useState(null)

  const ONE_HOUR = 3.6e6

  useEffect(_ => {
    console.log('🌠 FEEDBACK-HUB Started')
    // Comment handleAuth out to ignore auth locally
    handleAuth()
      .then(async _ => setUser(Object.freeze(await fetchUser())))
      .then(_ => {
        // No need to clear this interval, we must always refresh the token as long as the app is opened
        setInterval(refreshTokens, ONE_HOUR)
      })
  }, [])
  useEffect(_ => {
    if (user) {
      socket.emit('REGISTER_USER', user)
    }
  }, [user])

  const RoutesArray = [
    { path: '/', component: Dashboard },
    { path: '/events', component: Events },
    { path: '/issues', component: Issues },
    { path: '/events/:id', component: EventDetail }
  ]

  if (!user) {
    return <Spinner />
  }

  if (isUserAuthorized(user)) {
    return (
      <StrictMode>
        <Router>
          <UserContext.Provider value={user}>
            <Switch>
              <Layout>
                <Suspense fallback={<Spinner />}>
                  {RoutesArray.map(({ path, component }) => (
                    <Route exact path={path} component={component} key={path} />
                  ))}
                </Suspense>
              </Layout>
            </Switch>
          </UserContext.Provider>
        </Router>
      </StrictMode>
    )
  }

  return <Unauthorized />
}

export default App
