import React, { useState, useEffect, forwardRef, useRef } from 'react'
import Chart from "react-apexcharts";

const ClusterChart = () => {


    const [series, setSeries] = useState([
        {
            data: [
              
            ]
        }
    ])


    useEffect(_ => {
        fetch("/api/stats/data/graph/heatmap")
        .then(response => response.json())
        .then(stats => {
            console.log(stats)
            const new_series = stats.map(stat => {return {x: stat.name, y: stat.count}})
            console.log(new_series)
            setSeries([{data: new_series}])
        })
    }, []) 
    
    const [options, SetOptions] = useState({
        legend: {
            show: false
        },
        chart: {
            height: 350,
            type: 'treemap'
        },
    })




    return (
        <Chart options={options} series={series} type="treemap" height={350} />

    )
}

export default ClusterChart