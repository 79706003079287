import React from 'react'

const index = ({ onClick }) => {
  const styles = {
    position: 'fixed',
    left: '0',
    right: '0',
    bottom: '0',
    top: '64px',
    backgroundColor: 'rgba(0,0,0,.3)',
    zIndex: '-1'
  }

  return <div style={styles} onClick={onClick} />
}

export default index
