import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import React, {
    useContext,
    useEffect, useState, useRef
} from 'react'
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom'
import UserContext from '../../Context/userContext'
import socket from '../../ws/index'
import RatingBox from '../../Components/RatingBox'
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
    paper: {
        //   padding: theme.spacing(2),
        // color: theme.palette.text.secondary,
    },
}));


const EventDetail = _ => {

    const classes = useStyles()
    const user = useContext(UserContext)
    const [video, setVideo] = useState(null)
    const [issueTypes, setIssueTypes] = useState(null)
    const [issues, setIssues] = useState([])
    const [loading, setLoading] = useState(true)
    const [currentTime, setCurrentTime] = useState(null)
    const player = useRef(null);


    let { id } = useParams()
    // const id = "maeb47bd77"

    useEffect(() => {
        socket.on('VIDEO_LISTED', vid => {
            setLoading(false)
            setVideo(vid)
        })
        socket.on('ISSUE_REPORTED', new_issues => {
            setIssues(new_issues)
        })
        socket.on('ISSUE_DELETED', new_issues => {
            setIssues(new_issues)
        })
        socket.on('ISSUES_LISTED', new_issues => {
            setIssues(new_issues)
        })

        socket.emit('REQUEST_VIDEO', id)

        socket.on('ISSUE_TYPES_LISTED', vid => {
            setIssueTypes(vid)
        })

        socket.emit('LIST_ISSUE_TYPES', id)
    }, [id])

    useEffect(() => {

        if (video) {
            socket.emit('LIST_ISSUES', { id: video.event_guid, user_id: user.id })
        }
    }, [video, id])

    const delete_issue = (issue_id) => {
        socket.emit('DELETE_ISSUE', issue_id)
    }

    const report_issue = (issue_type_id) => {
        socket.emit('REPORT_ISSUE', { type: issue_type_id, game_guid: video.event_guid, user_id: user.id, timestamp: player.current.getCurrentTime() })
    }

    const issue_list = () => {
        if (!issues) {
            return <div />
        }
        return (<div className={classes.demo}>
            <List dense={true}>

                {issues.map(issue => (
                    <ListItem key={issue.id} onClick={_ => {player.current.seekTo(moment.utc(issue.stream_time).unix())}}>
                        <ListItemAvatar>
                            <IconButton edge="end" aria-label="delete" >
                                <PlayCircleOutlineIcon/>
                            </IconButton>
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${moment.utc(issue.stream_time).format("HH:mm:ss")} - ${issue.issue_type.name}`}
                            secondary={issue.issue_type.desciption}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon onClick={_ => {delete_issue({id: issue.id, game_guid: video.event_guid, user_id: user.id})}}/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </div>)
    }


    const issue_picker = () => {
        if (!issueTypes) {
            return <h1>Loading</h1>
        }

        return (
            <div>{
                issueTypes.map(issue_cat => {
                    return (
                        <Box key={issue_cat.id} style={{ marginBottom: '5px' }}>
                            <ButtonGroup
                                variant='contained'
                                color='primary'
                                aria-label='contained primary button group'>
                                {issue_cat.issue_types.map(issue_type => {
                                    return (
                                        <Tooltip key={issue_type.id} title={issue_type.desciption} aria-label={issue_type.name}>
                                            <Button onClick={_ => report_issue(issue_type.type)}>{issue_cat.name}: {issue_type.name}</Button>
                                        </Tooltip>
                                    )

                                })}

                            </ButtonGroup>
                        </Box>
                    )
                })
            }</div>)
    }

    if (loading) {
        return <h1>Loading</h1>
    }
    if (!video) {
        return <h1>404 Video Not Found</h1>
    } else {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={7} style={{ position: 'sticky' }}>
                    <Paper className={classes.paper} style={{ padding: '3px', overflow: 'hidden', borderRadius: '8px', background: 'black' }}>
                        <ReactPlayer ref={player}
                            width='100%' height='100%' url={video.vod} controls muted playsinline playing progressInterval={500} />

                    </Paper>
                    <Paper className={classes.paper} style={{ padding: '15px', overflow: 'hidden' }}>
                        {issue_picker()}
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={5}>
                    <Paper className={classes.paper} style={{padding: '15px 30px'}}>
                        <h1 style={{marginBottom:'5px'}}>{video.event}</h1>
                        <h3 style={{marginTop:'5px', marginBottom:'5px', color: 'rgb(93 93 93)'}}>{video.league} - {video.thing_name}</h3>
                        <h3 style={{marginTop:'5px', color: 'rgb(93 93 93)'}}>{ moment.utc(video.event_start).local().format('DD.MM.YYYY HH:mm')}</h3>
                        <RatingBox event_guid={video.event_guid} user_id={user.id}/>
                        </Paper>
                    <Paper className={classes.paper} style={{padding: '15px'}}>
                        <h2>{issues.length} Issues</h2>
                    </Paper>
                    <Paper className={classes.paper} >
                        {issue_list()}
                    </Paper>
                </Grid>

            </Grid>
        )
    }
}

export default EventDetail
