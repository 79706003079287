import React, { useState, useEffect, forwardRef, useRef } from 'react'
import Chart from 'react-apexcharts'
import moment from 'moment'

const HeatMap = props => {

  const generateData = function(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = (i + 1).toString();
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
        series.push({
          x: x,
          y: y
        });
        i++;
      }
      console.log(series)
      return series;
  }

  const [series, setSeries] = useState([
    {
      name: 'Synchronization',
      data: generateData(18, {
        min: 0,
        max: 90
      })
    },
    {
      name: 'Camera Lost Focus',
      data: generateData(18, {
        min: 0,
        max: 90
      })
    },
    {
      name: 'Colors',
      data: generateData(18, {
        min: 0,
        max: 90
      })
    },
    {
      name: 'Exposure',
      data: generateData(18, {
        min: 0,
        max: 90
      })
    },
    {
      name: 'Missed Goal',
      data: generateData(18, {
        min: 0,
        max: 90
      })
    },
    {
      name: 'Keeper',
      data: generateData(18, {
        min: 0,
        max: 90
      })
    }
  ])

  // Sorry, the follwing is quite ugly and barely readable.
  useEffect(_ => {
    fetch('/api/stats/data/graph/cluster')
      .then(response => response.json())
      .then(stats => {
        console.log(stats)
        const indexed_stats = stats.map(s => {return {...s, day: moment.utc(s.day).format('MM-DD')}})
        const issue_types = [... new Set(stats.map(s => s.issue_type))]
        const days = [...new Set(stats.map(s => {return moment.utc(s.day).format('MM-DD')}))]
        console.log(issue_types)
        console.log(days)
        const ser = issue_types.map(it => {
          let datapoints = []
          let startdate = moment();
          let issue_class = ''
          for (let i = 0; i < 5; i++) {
            const sel = startdate.format('MM-DD')
            // const start_z = startdate.
            let count = 0;
            indexed_stats.forEach(element => {
              if (element.day == sel && element.issue_type == it) {
                count = parseInt(element.count)
                issue_class = element.category
              }
            });

            
            startdate.subtract(1, 'd');
            datapoints.push({x: sel, y: count})
          }
          datapoints.reverse()


          return {name: `${issue_class}: ${it}`, data: datapoints}}
          )
        // const new_series = stats.map(stat => {
        //   return { x: stat.name, y: stat.count }
        // })
        console.log(ser)
        setSeries(ser)
      })
  }, [])

  // generateData = function(count, yrange) {
  //     var i = 0;
  //     var series = [];
  //     while (i < count) {
  //       var x = (i + 1).toString();
  //       var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
  //       series.push({
  //         x: x,
  //         y: y
  //       });
  //       i++;
  //     }
  //     return series;
  // }

  const [options, SetOptions] = useState({
    chart: {
      height: 450,
      type: 'heatmap'
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#008FFB']
  })

  return (
    <div id='chart'>
      <Chart
        options={options}
        series={series}
        type='heatmap'
        height={350}
      />
    </div>
  )
}
export default HeatMap
