import React, { useState, useEffect, forwardRef, useRef } from 'react'
import TrendChart from '../../Components/TrendChart'
import MultipleYAxisChart from '../../Components/MultipleYAxisChart'
import ClusterChart from '../../Components/ClusterChart'
import HeatMap from '../../Components/HeatMap'
import Box from '@material-ui/core/Box'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'

const Dashboard = () => {

    return (
        <Grid container spacing={3}>
               <Grid item xs={12} sm={12} style={{ position: 'sticky' }}>
                <Paper style={{ padding: '15px', overflow: 'hidden' }}>
                    <h4>Quality Trend</h4>
                    <MultipleYAxisChart/>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} style={{ position: 'sticky' }}>
                <Paper style={{ padding: '15px', overflow: 'hidden' }}>
                    <h4>Issue Types</h4>
                    <ClusterChart />
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} style={{ position: 'sticky' }}>
                <Paper style={{ padding: '15px', overflow: 'hidden' }}>
                    <h4>Issue Clusters</h4>
                    <HeatMap />
                </Paper>
            </Grid>
        </Grid>
    )
}
export default Dashboard