const localStorage = window.localStorage

const AUTH_BASE = new URL('https://master-auth.sporttotal.co/')
const FEEDBACK_BASE = window.location
let AUTH_API_BASE

// Cant use useParams hook outside react router
const FEEDBACK_PARAMS = new URLSearchParams(FEEDBACK_BASE.search)
const CODE = FEEDBACK_PARAMS.get('code')

const singleSignOnParams = new URLSearchParams({
  client: 'feedback',
  redirect: FEEDBACK_BASE.origin
})

const AUTH_REDIRECT = `${AUTH_BASE.toString()}?${singleSignOnParams.toString()}`
const AUTH_SIGN_OUT_REDIRECT = `${AUTH_BASE.toString()}logout?${singleSignOnParams.toString()}`

const fetchAuthAPIBase = async _ => {
  AUTH_API_BASE = await fetch(
    'https://master-registry.sporttotal.co/@sporttotaltv/auth-api'
  ).then(r => r.text())
}

const setTokens = async _ => {
  localStorage.clear()

  const response = await fetch(`${AUTH_API_BASE}/token?code=${CODE}`)
  const json = await response.json()
  const {
    data: { refreshToken, token }
  } = json
  localStorage.setItem('refreshToken', refreshToken)
  localStorage.setItem('token', token)

  // Call refresh tokens to immediately consume the first refresh token in order to obtain tokenExpiresOn
  refreshTokens()
}

const fetchUser = async _ => {
  const token = localStorage.getItem('token')

  if (token) {
    const response = await fetch(`${AUTH_API_BASE}/user`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (!response.ok) {
      localStorage.clear()
      window.location.replace(AUTH_SIGN_OUT_REDIRECT)
    }

    const { data } = await response.json()

    return data
  } else {
    return { name: 'Dev' }
  }
}

const isUserAuthorized = user => {
  if (
    !user.email.endsWith('@sporttotal.tv') &&
    !user.email.endsWith('@sporttotal.com')
  ) {
    return false
  }

  return true
}

const refreshTokens = async _ => {
  await fetchAuthAPIBase()

  const currentRefreshToken = localStorage.getItem('refreshToken')

  if (!currentRefreshToken) {
    window.location.replace(AUTH_REDIRECT)
  }

  const response = await fetch(`${AUTH_API_BASE}/token`, {
    headers: {
      Authorization: `Bearer ${currentRefreshToken}`
    }
  })

  if (!response.ok) {
    localStorage.clear()
    window.location.replace(AUTH_SIGN_OUT_REDIRECT)
  }

  const json = await response.json()

  const {
    data: { refreshToken, token, tokenExpiresOn }
  } = json
  localStorage.setItem('refreshToken', refreshToken)
  localStorage.setItem('token', token)
  localStorage.setItem('tokenExpiresOn', tokenExpiresOn)
}

const handleSignOut = _ => {
  localStorage.clear()
  window.location.replace(AUTH_SIGN_OUT_REDIRECT)
}

const handleAuth = async _ => {
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refreshToken')
  const tokenExpiresOn = localStorage.getItem('tokenExpiresOn')

  if (!CODE && (!refreshToken || !token)) {
    // No Code or tokens found, user needs to login
    window.location.replace(AUTH_REDIRECT)
  }

  if (tokenExpiresOn && Date.now() > new Date(parseInt(tokenExpiresOn))) {
    // Token is expired, clear all local data and redirect to login
    localStorage.clear()
    window.location.replace(AUTH_REDIRECT)
  }

  // The Auth API Base Needs to be fetched from V2 Registry Service
  await fetchAuthAPIBase()

  if (CODE && (!token || !refreshToken)) {
    // User Logged in but no tokens found in LocalStorage

    await setTokens()
  }
}

export { handleAuth, fetchUser, refreshTokens, handleSignOut, isUserAuthorized }
