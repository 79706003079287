import React, { useState, useEffect, forwardRef, useRef } from 'react'
import { Chip } from '@material-ui/core'
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  PauseCircleFilled as PauseCircleFilledIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from '@material-ui/icons'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import socket from '../../ws/index'
import moment from 'moment'
import Rating from '@material-ui/lab/Rating'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers'

const columns = [
  {
    field: 'Status',
    title: 'Status',
    lookup: {
      active: 'Streaming',
      scheduled: 'Scheduled',
      inactive: 'Inactive'
    },
    render: rowData => (
      <Chip
        color={rowData.status === 'active' ? 'primary' : 'default'}
        size='small'
        label={rowData.status === 'active' ? 'Streaming' : 'Scheduled'}
        icon={
          rowData.status === 'active' ? (
            <PlayCircleFilledIcon />
          ) : (
            <PauseCircleFilledIcon />
          )
        }
      />
    )
  },
  {
    title: 'Name',
    field: 'event',
    render: rowData => (
      <Link to={`/events/${rowData.content_uuid || rowData.event_guid}`}>
        {rowData.event}
      </Link>
    )
  },
  {
    title: 'League',
    field: 'league'
  },
  {
    title: 'Start',
    field: 'event_start',
    render: ({ event_start }) =>
      moment.utc(event_start).local().format('DD.MM.YYYY HH:mm')
  },
  {
    title: 'Autoproduction Score',
    field: 'ap_score',
    render: rowData => {
      return rowData.avg_score ? (
        <Rating name='disabled' value={rowData.avg_score / 2} disabled />
      ) : (
        <span />
      )
    }
  },
  {
    title: 'Issues Recorded',
    field: 'issue_count'
  },
  {
    title: 'Thing name',
    field: 'thing_name'
  },
  {
    title: 'Visible',
    field: 'visible',
    render: rowData => (rowData.permission == 'all' ? 'website' : 'internal'),
    lookup: { internal: 'internal', website: 'website', all: 'all' }
  },
  {
    title: 'Content id',
    field: 'content_uuid',
    render: rowData => (
      <a href={`https://www.sporttotal.tv/${rowData.content_uuid}`}>
        {rowData.content_uuid}
      </a>
    )
  }
]

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const Events = _ => {
  const tableRef = useRef()
  const [videos, setVideos] = useState([])
  const [selectedDate, setSelectedDate] = React.useState(null)
  const [selectedCameratype, setCameratype] = React.useState(null)

  const handleDateChange = date => {
    setSelectedDate(date)
  }
  const handleCameratypeChange = Cameratype => {
    setCameratype(Cameratype.target.value)
  }

  useEffect(() => {
    socket.on('VIDEOS_LISTED', vid => {
      setVideos(vid)
    })
    socket.emit('REQUEST_VIDEOS')
  }, [])

  const filteredVideos = _ => {
    let newVideos = videos
    if (selectedDate != null) {
      newVideos = newVideos.filter(
        video =>
          video.thing_name &&
          moment.utc(video.event_start).local().format('DD.MM.YYYY') ==
            moment.utc(selectedDate).local().format('DD.MM.YYYY')
      )
    }
    if (selectedCameratype != null) {
      newVideos = newVideos.filter(
        video =>
          video.thing_name &&
          video.thing_name.includes('_' + selectedCameratype)
      )
    }
    return newVideos
  }

  console.log(videos)
  return (
    // <div>

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Paper
            style={{ padding: '15px', overflow: 'hidden' }}
            justify='center'>
            <h2 style={{ fontWeight: 400, marginBottom: '0px' }}>
              {' '}
              Filter Section
            </h2>
            {
              <KeyboardDatePicker
                disableToolbar
                string
                variant='inline'
                format='MM/dd/yyyy'
                margin='normal'
                id='date-picker-inline'
                label='Filter by Date'
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            }

            <FormControl
              sx={{ minWidth: '200px' }}
              style={{
                minWidth: '200px',
                marginLeft: '15px',
                marginTop: '16px'
              }}>
              <InputLabel>Cameratype</InputLabel>
              <Select
                value={selectedCameratype}
                onChange={handleCameratypeChange}
                label='Cameratype'>
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'M1'}>M1</MenuItem>
                <MenuItem value={'P1'}>P1</MenuItem>
                <MenuItem value={'S1'}>S1</MenuItem>
                <MenuItem value={'S2'}>S2</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12}>
          <MaterialTable
            tableRef={tableRef}
            icons={tableIcons}
            columns={columns}
            title='Events'
            // data={videos.filter(video => video.thing_name && video.thing_name.includes("_" + selectedCameratype))}
            data={filteredVideos()}
            options={{
              sorting: true,
              filtering: true,
              pageSize: videos.length > 100 ? 100 : 50,
              exportButton: true,
              exportAllData: true
            }}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>

    // </div>
  )
}

export default Events
