import { createTheme } from '@material-ui/core'

const overrides = createTheme({
  palette: {
    primary: { main: '#da1e39' }
  },
  overrides: {
    MuiPaper: {
      root: { overflow: 'hidden' }
    }
  }
})

export default overrides
