import React, { useState } from 'react'

import { ThemeProvider } from '@material-ui/core'
import { CssBaseline } from '@material-ui/core'

import Nav from '../Nav'
import Header from '../Header'
import styles from './styles'
import overrides from './overrides'

const Layout = ({ children }) => {
  const classes = styles()
  const [open, setOpen] = useState(false)

  const handleDrawerOpen = _ => setOpen(true)
  const handleDrawerClose = _ => setOpen(false)

  return (
    <ThemeProvider theme={overrides}>
      <div className={classes.root}>
        <CssBaseline />
        <Header handleDrawerOpen={handleDrawerOpen} open={open} />
        <Nav open={open} handleDrawerClose={handleDrawerClose} />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.container}>{children}</div>
        </main>
      </div>
    </ThemeProvider>
  )
}

export default Layout
